import axios from 'axios';


const request = function (method, url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url,
      data: data
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default request