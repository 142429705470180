import { createApp } from 'vue';
import App from './App.vue';
import router from './route/router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import axios from 'axios';
import api from './api/api.js'

const app = createApp(App);
app.use(ElementPlus)
app.use(router);

if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = '/api';
}

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$api = api;

app.mount('#app');
