<template>
  <div class="page">

    <myHeader class="my-header"></myHeader>


    <div class="banner-box">
      <img class="banner" src="../assets/banner.webp" alt="">
    </div>

    <div class="plate1">
      <div class="plate1-left">
        <div class="plate1-title">关于维他希汀</div>
        <div class="plate1-left-content">
          维他希汀为香港注册商标品牌，品牌专注于营养膳食领域，致力于为消费者提供高品质、科学配方的营养产品。品牌秉持着对健康的执着追求和对品质的严格把控，不断研发创新，以满足不同消费者的需求。
        </div>
        <div>
          <img style="width: 100%;" src="../assets/hk.jpg" alt="">
        </div>
      </div>
      <div class="plate1-right">
        <div class="plate1-title">产品系列</div>

        <div class="plate1-right-mid">
          <div class="plate1-right-mid-cell" v-for="(item, index) of fontList" :key="index">
            <div :class="'iconfont ' + item.icon"></div>
            <div class="plate1-right-mid-name">{{ item.name }}</div>
          </div>
        </div>

        <div class="plate1-right-foot">
          <div class="p-list">
            <div class="p-item">
              <img class="p-item-img" src="https://vesitin.com/resources/uploadImg/1733108223169-DHA.png" alt="">
              <div>DHA凝胶</div>
            </div>

            <div class="p-item">
              <img class="p-item-img" src="https://vesitin.com/resources/uploadImg/1733107520326-%E7%BA%B3%E8%B1%86.jpg"
                alt="">
              <div>纳豆激酶</div>
            </div>

            <div class="p-item">
              <img class="p-item-img" src="https://vesitin.com/resources/uploadImg/1733108359567-q10.png" alt="">
              <div>辅酶 Q10</div>
            </div>

          </div>
        </div>


      </div>
    </div>


    <div class="honor">
      <div>
        <div class="honoe-title">认证</div>
        <div class="honor-box">
          <div v-for="(item, index) of Certifications" :key="index">
            <img class="cer-img" :src="require('@/assets/' + item.img)" alt="">
          </div>
        </div>
      </div>

      <div>
        <div class="honoe-title">安全阐述</div>
        <div class="honor-box">
          <div v-for="(item, index) of Safely" :key="index">
            <img class="cer-img" :src="require('@/assets/' + item.img)" alt="">
          </div>
        </div>
      </div>
    </div>






    <myFooter></myFooter>



    <div class="back-box" @click="backTop">
      <div class="iconfont icon-xiangshang1"></div>
    </div>


  </div>
</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {
      list: [
        { img: 'distr/P01.png', name: 'Lipase Fat Burnuing', price: '47.00', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
        { img: 'distr/P02.png', name: 'TRIBULUS plus ZINC', price: '109.00', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P03.png', name: 'Intestinal GUT SUPPORT', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P04.png', name: 'CO Q10', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
        { img: 'distr/P05.png', name: 'EYE FIT', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' }
      ],
      Certifications: [
        { img: 'FDA.png' },
        { img: 'h02.jpg' },
        { img: 'h03.jpg' },
        { img: 'h04.jpg' },
        { img: 'h05.jpg' }
      ],
      Safely: [
        { img: 'h2-1_01.jpg' },
        { img: 'h2-1_02.jpg' },
        { img: 'h2-1_03.jpg' },
        { img: 'h2-1_04.jpg' },
        { img: 'h2-1_05.jpg' },
        { img: 'h2-1_06.jpg' }
      ],
      fontList: [
        { name: '进口鱼油', icon: 'icon-yu' },
        { name: '藻油DHA', icon: 'icon-shape-' },
        { name: '维生素', icon: 'icon-shuiguo' }
      ]
    }
  },
  mounted() {
    this.$axios({
      method: 'post',
      url: '/api/visit/client/createVisit',
      data: {
        pageName: 'home'
      }
    }).then(res => {
      console.log(res);
    })
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped lang="less">
.banner-box {
  text-align: center;
  background-color: #e9e9eb;
  margin-top: 140px;

  .banner {
    width: 100%;
    display: block;
  }
}

.video-box {
  padding: 20px 10vw 0 10vw;

  .publicity-video {
    width: 100%;
  }
}

.plate1 {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;

  .plate1-left {
    width: 37.6%;

    .plate1-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .plate1-left-content {
      font-size: 13px;
    }
  }

  .plate1-right {
    width: 57.2%;

    .plate1-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .plate1-right-mid {
      display: flex;
      justify-content: space-around;
      font-size: 16px;


      .plate1-right-mid-cell {
        text-align: center;

        .iconfont {
          font-size: 36px;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }


    }

    .plate1-right-foot {
      .p-list {
        display: flex;

        .p-item {
          text-align: center;

          .p-item-img {
            width: 200px;
          }
        }
      }
    }

  }
}

@media (min-width: 1200px) {
  .plate1 {
    width: 1170px;
    margin: 0 auto;
  }
}





.honor {
  .honoe-title {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    padding: 20px 0;
  }

  .honor-box {
    margin: 10px 0;
    display: flex;
    padding: 0 10vw;
    justify-content: space-around;
    flex-wrap: wrap;

    .cer-img {
      width: 117px;
      height: auto;
    }
  }

}



.back-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

@media (max-width: 768px) {
  .banner {
    width: 100vw;
  }

  .plate1 {
    display: block;
    padding-top: 30px;

    .plate1-left {
      width: 100%;
      padding: 10px 5% 0 5%
    }

    .plate1-right {
      width: 100%;
      padding: 10px 5% 0 5%;

      .plate1-right-foot {
        .p-list {
          display: flex;

          .p-item {
            text-align: center;

            .p-item-img {
              width: 100px !important;
            }
          }
        }
      }
    }
  }

  .video-box {
    padding: 5px 0vw 0 0vw;

    .publicity-video {
      width: 100%;
    }
  }


  .honor {
    .honoe-title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      padding: 20px 0;
    }

    .honor-box {
      margin: 10px 0;
      display: flex;
      padding: 0 10vw;
      justify-content: space-around;
      flex-wrap: wrap;

      .cer-img {
        width: 90px;
        height: auto;
      }
    }

  }


  .p-list {
    padding: 0;
    flex-wrap: wrap;
  }

  .p-item-img {
    width: 90vw !important;
    height: auto !important;
  }

  .footer {
    height: 138px;
  }
}
</style>
