<template>
    <div class="footer-box">
        <myHeader class="my-header"></myHeader>

        <div class="top-img-box">
            <img class="top-img" src="../assets/About-Us-Header.jpg" alt="">
            <div class="top-img-mask"></div>
        </div>

        <div class="aboutus-content">
            <div class="module-title">
                Vesitin's Synergy
            </div>

            <div>
                Vesitin's synergistic approach to supplements is rooted in the meticulous selection of quality
                ingredients that work in concert to amplify your health benefits. By harnessing the collective power of
                these components, our solutions transcend ordinary supplementation. Each ingredient is chosen not only
                for its individual efficacy but also for its ability to enhance the effects of others. This strategic
                combination results in a compounded impact on your wellness, delivering more than the sum of its parts
                and ensuring that every product from VESITIN is a step towards optimal health.
            </div>


            <div class="aboutus-content-list">

                <div class="aboutus-content-item">
                    <div class="aboutus-content-item-img">
                        <img class="item-img" src="../assets/synergy.webp" alt="">
                    </div>
                    <div class="aboutus-content-item-content">
                        <div class="module-title">
                            What We Care About
                        </div>

                        <div>
                            At Vesitin, quality and safety are top priority. Manufactured in GMP-compliant and
                            FDA-registered facilities, we ensure each product meets the highest standards of excellence.
                            We blend scientific innovation with natural ingredients to create supplements that add and
                            enhance your health, reflecting our commitment to unlocking your full wellness potential.
                        </div>

                    </div>
                </div>
                <div class="aboutus-content-item">
                    <div class="aboutus-content-item-content">
                        <div class="module-title">
                            Why Vesitin
                        </div>

                        <div>
                            When it comes to your self-care, choose Vesitin as your trusted partner. Our dietary
                            supplements are designed to be of best quality to support your optimal health and
                            well-being. With our commitment to safety and innovation, Vesitin is here to help you
                            prioritize your health and make self-care a seamless part of your daily routine.
                        </div>

                    </div>
                    <div class="aboutus-content-item-img">
                        <img class="item-img" src="../assets/synergy.webp" alt="">
                    </div>

                </div>

            </div>
        </div>


        <div class="honor">
            <div>
                <div class="honoe-title">Certifications</div>
                <div class="honor-box">
                    <div>
                        <img class="cer-img" src="../assets/FDA.png" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h02.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h03.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h04.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h05.jpg" alt="">
                    </div>
                </div>
            </div>

            <div>
                <div class="honoe-title">Safely Formulated</div>
                <div class="honor-box">
                    <div>
                        <img class="cer-img" src="../assets/h2-1_01.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_02.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_03.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_04.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_05.jpg" alt="">
                    </div>
                    <div>
                        <img class="cer-img" src="../assets/h2-1_06.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>

        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {

        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>


<style lang="less" scoped>
.footer-box {
    padding-top: 140px;
}

.top-img-box {
    height: 300px;
    overflow-y: hidden;

    .top-img {
        width: 100%;
    }

    .top-img ::before {
        background-color: rgba(255, 255, 255, 0.1);

    }
}

.aboutus-content {
    padding: 0 20vw;
    line-height: 30px;
    background-color: white;

    .module-title {
        font-size: 35px;
        font-weight: 700;
        line-height: 50px;
        padding: 30px 0;
    }
}



.honor {
    .honoe-title {
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        padding: 20px 0;
    }

    .honor-box {
        margin: 10px 0;
        display: flex;
        padding: 0 10vw;
        justify-content: space-around;
        flex-wrap: wrap;

        .cer-img {
            width: 117px;
            height: auto;
        }
    }

}


.aboutus-content-item {
    display: flex;

    .aboutus-content-item-img {

        width: 50%;
        height: 350px;
        overflow: hidden;
        .item-img {
            width: 100%;
            height: auto;
        }
    }

    .aboutus-content-item-content {
        width: 50%;
        padding: 20px;
    }
}



@media (max-width: 768px) {

    .top-img-box {
        height: 110px;
    }


    .aboutus-content {
        padding: 0 8vw;
    }


    .honor {
        .honoe-title {
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            padding: 20px 0;
        }

        .honor-box {
            margin: 10px 0;
            display: flex;
            padding: 0 10vw;
            justify-content: space-around;
            flex-wrap: wrap;

            .cer-img {
                width: 90px;
                height: auto;
            }
        }

    }

    .aboutus-content-item {
        display: flex;
        flex-wrap: wrap;

        .aboutus-content-item-img {
            // background-color: #666;
            width: 100%;

            .item-img {
                width: 100%;
                height: auto;
            }
        }

        .aboutus-content-item-content {
            width: 100%;
            padding: 20px;
        }
    }

}
</style>