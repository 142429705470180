<template>
    <div class="header-box">
        <div class="header">
            <div></div>
            <div class="header-right">
                <span class="iconfont icon-dianhua"></span>
                <span class="callUs">Call us</span>
                <span>4008-873-528</span>
            </div>
        </div>

        <div class="nav">
            <div class="meau-box">
                <div class="iconfont icon-ego-menu" @click="meauSwitch"></div>
                <div class="meau-list" v-show="meauShow">
                    <div class="meau-item" v-for="(item, index) of navList" :key="index" @click="navigatorTo"
                        :data-url="item.url">{{ item.name }}</div>
                </div>
            </div>

            <div class="logo-box">
                <img class="logo" data-url="/HomePage" src="../assets/vesitinLogo.webp" alt="">
            </div>

            <div class="nav-list">

                <div class="nav-item" v-for="(item, index) of navList" :key="index">
                    <div @click="navigatorTo" :data-url="item.url">{{ item.name }}</div>
                    <div @click="navigatorTo" :data-url="item.url" class="nav-item-ename">{{ item.ename }}</div>
                </div>
            </div>
            <div class="login-box" @click="navigatorTo" data-url="/Login">Login</div>
        </div>
    </div>

</template>


<script>
export default {
    name: 'myHeader',
    data() {
        return {
            meauShow: false,
            navList: [
                { name: '首页', ename: 'Home', url: '/HomePage' },
                // { name: '关于我们', ename: 'About Us', url: '/AboutUs' },
                { name: '产品介绍', ename: 'Product', url: '/Shop' },
                { name: '常见问题', ename: 'FAQs', url: '/FAQs' },
                { name: '联系我们', ename: 'Contact Us', url: '/ContactUs' },
                { name: '大陆分销', ename: 'Distribution', url: '/Distribution' },
            ]
        }
    },

    methods: {
        meauSwitch() {

            this.meauShow = !this.meauShow;

            this.$emit('data-meau', this.meauShow);
        },

        navigatorTo(e) {
            this.$router.push(e.target.dataset.url)
        },
    }
}

</script>


<style lang="less" scoped>
.header {
    margin: 0 10vw;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .header-right {
        display: flex;

        .icon-dianhua {
            font-size: 16px;
            margin-right: 16px;
            font-weight: 700;
        }

        .callUs {
            margin-right: 10px;
        }
    }


}

.nav {
    padding: 0 10vw;
    height: 105px;
    display: flex;
    justify-content: space-between;
    color: #333;

    .meau-box {
        display: none;
    }

    .icon-ego-menu {
        line-height: 105px;
        width: 50px;
    }

    .meau-list {
        z-index: 9999;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: white;

        .meau-item {
            line-height: 40px;
            padding: 0 20px;
        }

    }

    .login-box {
        line-height: 105px;
        font-size: 18px;
        font-weight: 700;
    }

    .logo-box {
        padding: 10px 0;

        .logo {
            height: 85px;
        }
    }

    .nav-list {
        display: flex;
        width: 50vw;
        font-size: 15px;

        .nav-item {
            padding: 26px 10px;
            font-weight: 700;
            line-height: 22px;
            text-align: center;
        }

        .nav-item-ename {
            font-size: 13px;
            font-weight: 500;
        }

        .nav-item:hover {
            cursor: pointer;
        }
    }

}

@media (max-width: 768px) {

    .header-left {
        font-size: 12px !important;
    }

    .phone {
        display: none !important;
    }

    .header {
        padding: 0 20px;
    }

    .nav {
        padding: 0 20px;
    }

    .nav-list {
        display: none !important;
    }

    .meau-box {
        display: block !important;
    }
}
</style>