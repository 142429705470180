<template>
    <div>
        <myHeader class="my-header"></myHeader>


        <div class="demo-collapse">
            <div class="page-title">
                常见问题
            </div>

            <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item title="1.什么是膳食补充剂?" name="1">
                    <div>
                        膳食补充剂，顾名思意，是一种旨在帮助实现更好的均衡饮食的产品，通常含有维生素、矿物质、草药或其他植物性药物。
                    </div>
                </el-collapse-item>
                <el-collapse-item title="2. 膳食补充剂安全吗？" name="2">
                    <div>
                        是的，膳食补充剂通常是安全的，如果在指导下使用，并且他们已经批准了相关的卫生当局。Vesitin的补充剂是在FDA批准的产品，并持有几个质量和安全认证。然而，在开始服用任何补充剂之前，咨询医疗保健提供者是很重要的，特别是对于有健康问题的人或正在服用其他药物的人，因为补充剂可能与药物相互作用。
                    </div>
                </el-collapse-item>
                <el-collapse-item title="3. 我怎么知道该服用哪种补品？" name="3">
                    <div>
                        最好咨询医疗保健专业人员或注册营养师，根据您的个人需求和健康状况确定适合您的补充剂。一般来说，Vesitin补充剂最适合健康状况一般的成年人，并针对需要改善的部分。
                    </div>
                </el-collapse-item>
                <el-collapse-item title="4. 我应该怎样服用膳食补充剂？" name="4">
                    <div>
                        服用膳食补充剂的推荐剂量和方法可能因产品而异。一定要阅读标签，并按照瓶子上或包装上的说明服用。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="5.膳食补充剂受FDA监管吗？" name="5">
                    <div>
                        FDA监管处方药和非处方药，但不包括膳食补充剂。也就是说，我们的天然100%无药物补充剂是在美国FDA认证的工厂生产的，符合美国食品和药物管理局（FDA）的要求，并使用现行良好生产规范（cgmp）来确保其安全性。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="6. 产品可以推荐给所有类型的人吗？" name="6">
                    <div>
                        我们不建议任何18岁以下的人使用Vsitin产品。此外，虽然我们没有理由相信它有害，但还没有足够的研究让我们说Vsitin产品适用于怀孕期间。因此，我们建议在怀孕、尝试怀孕或哺乳期间不使用Vsitin产品。如果您有疾病或正在服用药物（特别是抗凝血剂和血液稀释药物），请咨询您的医生。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="7. 我在哪里可以买到Vsitin产品？" name="7">
                    <div>
                        我们致力于使VESITIN易于获取。为了确保您获得最好的产品和服务，Vesitin仅在Vesitin.com，拼多多，以及我们的官方品牌平台上提供。未经授权的卖家已发现假冒Vesitin产品。这些产品是不安全的，如果您需要帮助确认或报告卖家，请发送电子邮件至info@VESITIN.com。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="8. 如果我对产品不满意，可以退货吗?" name="8">
                    <div>
                        是的，您的满意是我们的首要任务。在Vesitin.com上购买的Vesitin产品有90天退款保证政策。如果您对您的产品不满意，请按照这里的退货请求步骤操作。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="9. 我可以在服用其他药物的同时服用膳食补充剂吗？" name="9">
                    <div>
                        如果您目前正在服用其他药物，那么在服用任何新的补充剂之前与医疗保健专业人员交谈是很重要的，因为可能存在潜在的相互作用。然而，Vesitin补充剂可以一起服用，例如，你可以每天服用两粒脂肪酶胶囊和一粒叶黄素片。
                    </div>
                </el-collapse-item>

                <el-collapse-item title="10. 我应该如何保存我的补品?" name="10">
                    <div>
                        Vesitin产品装在琥珀色玻璃瓶中或半透明塑料瓶中，以防止光吸收，并有助于保持补充剂的营养完整性。该产品的成分是天然提取物。应严密密封，存放于远离阳光直射的环境中，并保持通风干燥，防止成分受潮氧化，影响功效。
                    </div>

                </el-collapse-item>

                <el-collapse-item title="11.Vsitin产品含有任何药物吗？" name="11">
                    <div>
                        不，Vsitin产品是100%无毒的。
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <myFooter class="footer"></myFooter>
    </div>

</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';

export default {
    data() {
        return {
            activeNames: ''
        }
    },
    components: {
        myFooter,
        myHeader
    },

    methods: {
        handleChange: (val) => {
            console.log(val)
        }
    }
}

</script>

<style>
.demo-collapse {
    margin: 140px 0 0px 0;
    padding: 0 20vw;
}

.page-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 100px;
    height: 100px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}


@media (max-width: 768px) {
    .page-title {
        font-size: 18px !important;
    }

    .demo-collapse {
        padding: 2vw !important;
    }
}
</style>