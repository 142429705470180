<template>
    <div class="footer-box">

        <div class="jsns-box">
            <div class="other-info-left other-info-cell">
                <div class="info-cell">香港沙湾荔枝角道863号</div>
                <div class="info-cell">4008-873-528</div>
                <div class="info-cell">info@vesitin.com</div>

                <!-- <div style="word-wrap:break-word;">These statements have not been evaluated by the<br> Food and Drug
                    Administration. The products are<br> not
                    intended to diagnose, treat, cure or prevent<br> any disease.</div> -->
            </div>
            <div class="jsns-box-right other-info-cell">
                <a class="info-cell"
                    href="https://baike.baidu.com/item/%E6%B1%9F%E8%8B%8F%E5%A8%9C%E8%8E%8E%E5%8C%BB%E7%96%97%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/64612738?fr=ge_ala">江苏娜莎医疗有限公司
                    版权所有</a>
                <div class="info-cell">
                    <a href="https://beian.miit.gov.cn/">苏ICP备2024115561号-1</a>
                </div>
                <div class="info-cell">
                    <a href="https://beian.miit.gov.cn/">苏公网安备32132202001291号</a>
                </div>
            </div>

            <div class="other-info-right other-info-cell">
                <div>
                    <div class="info-cell">隐私政策</div>
                    <div class="info-cell">Cookie 政策</div>
                    <div class="info-cell">运输、退货和退款政策</div>
                    <!-- <div class="info-cell">90天退款保证</div> -->
                </div>
            </div>



        </div>

    </div>


</template>


<script>
export default {
    name: 'myFooter',
    data() {
        return {}
    }
}

</script>


<style lang="less" scoped>
.other-infor {
    background-color: #F3F3F1;
    display: flex;
    padding: 50px 0;
    justify-content: space-around;
    flex-wrap: wrap;
}

.other-info-cell {
    margin-bottom: 20px;
    font-size: 12px;

    .other-info-title {
        line-height: 30px;
        text-align: center;
        font-size: 22px;
    }
}

.jsns-box {
    color: white;
    font-size: 12px;
    text-align: center;
    height: 136px;
    background: url('../assets/foot_bg.jpg');
    display: flex;
    justify-content: space-around;

    .other-info-cell {
        margin-top: 10px;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.info-cell {
    line-height: 36px;
    height: 36px;
}

@media (max-width: 768px) {
    .jsns-box {
        display: block;
        background-image: url('../assets/foot_bg.jpg');
        background-size: cover;
        height: auto;
    }

    .other-info-right {
        margin-bottom: 0;
    }

    .jsns {
        display: none;
    }

}
</style>