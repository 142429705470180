<template>
  <div class="footer-box">
    <myHeader class="my-header"></myHeader>

    <div class="login-container">

    </div>
    <myFooter></myFooter>
  </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'LoginPage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {

    }
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style>
.login-container {
  margin-top: 140px;
}

.back-box {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 5px;
  background-color: black;
  color: white;
}
</style>
